import styles from '@/styles/Controls/ThirdLevelControls.module.scss'

const ThirdLevelControls = ({children}:{children:any;}) => {
	return (
		<div className={styles.third_level_controls_holder}>
			{children}
		</div>
	)
}

export default ThirdLevelControls;