import { fetchStorageCardById } from "@/api/fetchStorageCards"
import Link from "next/link";
import { useEffect, useState } from "react";


export const STORAGE_CARD_MATERIAL_TYPES = {
	FLAT_SURFACE_MATERIAL: 1, //plošný materiál
	METALLURGICAL_MATERIAL: 2, //hutní materiál
	COATING_MATERIAL: 3, //nátěrový materiál,
	SEMIFINISHED_PRODUCTS: 4, //dodané polotovary
}

export const getStorageCardLink = (storage_card_id: number, title: string) => {
	const detail_url = "/warehouse/detail/" + storage_card_id;
	return <>
		<Link href={detail_url}>
			{title}
		</Link>
	</>;
}

export const getStorageCardName = (storageCard: any) => {
	const renderParameters = [
		storageCard?.material,
		`${storageCard?.thickness} mm`,
		storageCard?.surface_finish,
		storageCard?.relief,
		storageCard?.wrap,
		`${storageCard?.width}x${storageCard?.length}`
	].filter((item: any) => {
		return item !== undefined ? item?.toString()?.length > 0 || false : false;
	});

	return storageCard ? `${storageCard?.id} | ${storageCard?.material_category_name} - ${renderParameters.join(" ")}` : null;
}