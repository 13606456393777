import GenericProgress from "@/components/generic/GenericProgress";
import { Close } from "@mui/icons-material";
import { Alert, Backdrop, IconButton, Snackbar } from "@mui/material";
import { useState, useContext, createContext } from "react";

const LoadingContext = createContext({});

type MessageTypes = 'success' | 'info' | 'error' | 'warning' | undefined;

export const LoadingProvider = ({ children }: { children: any }) => {
	const [globalLoading, setGlobalLoading] = useState<boolean>(false);

	const showGlobalLoading = () => {
		setGlobalLoading(true);
	}

	const hideGlobalLoading = () => {
		setGlobalLoading(false);
	}

	return (
		<LoadingContext.Provider value={{
			globalLoading,
			showGlobalLoading,
			hideGlobalLoading
		}}>
			<Backdrop
				open={globalLoading}
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 200 }}
			>
				<GenericProgress show={true} title="Loading" />
			</Backdrop>
			{children}
		</LoadingContext.Provider>
	)
}

interface IAlertMessage {
	type?: MessageTypes;
	open: boolean;
	setOpen: (state: boolean) => void;
	customMessage?: string;
	setCustomMessage?: (message: string | undefined) => void;
}
const AlertMessage = ({ type, open, setOpen, customMessage, setCustomMessage }: IAlertMessage) => {
	const closeMessage = () => {
		setOpen(false);
		if (setCustomMessage) {
			setCustomMessage(undefined);
		}
	}

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			open={open}
			onClose={() => {
				closeMessage();
			}}
			autoHideDuration={5000}
		>
			<Alert
				severity={type || 'info'}
				action={(
					<IconButton
						aria-label="close"
						color="inherit"
						size="small"
						onClick={() => {
							closeMessage();
						}}
					>
						<Close fontSize="inherit" />
					</IconButton>
				)}
			>
				{customMessage ? customMessage : `Message`}
			</Alert>
		</Snackbar>
	)
}

export const useLoading = (): any => useContext(LoadingContext);