export function stripUrlSlashes(item_path_temp:string) {

	var item_path = item_path_temp;
	if (item_path.charAt(0) === '/') {
		item_path = item_path.slice(1);
	}

	item_path.replace(/\/+$/, '');

	return item_path;
}