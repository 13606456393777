export const replaceCommaWithDot = (str: string) => {
	return str.replace(/\,/g, '.');
}

export const replaceLeadingZeros = (str: string) => {
	return str.replace(/^0+(?=\d)/, '');
}

export const addZeroToBeginningDecimal = (str: string) => {
	return str.startsWith(".") ? `0${str}` : str;
}