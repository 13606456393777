import useSWR from "swr"

export const makeSwrRequest = (url:string | null,method?:'GET' | 'POST' | 'PUT') => {
	if(!method) {
		method = 'GET';
	}
	const fetcher = async (url: string) => {
		const res = await fetch(url, {
			method: method
		});
		const data = await res.json();
		return data;
	};

	return useSWR(url, fetcher);
}