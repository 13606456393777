import styles from '@/styles/Search/Search.module.scss';

import { useEffect, useRef, useState } from "react";
import GenericTextField from "../generic/GenericTextField";
import { Popover } from "@mui/material";
import { ISearchCategory } from '@/types/Search';
import { IContact } from '@/types/AddressBookTypes';
import { getFullName } from 'helpers/AddressBook';
import Link from 'next/link';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/defs/Date';
import { IStorageCard } from '@/types/StorageCards';
import { getStorageCardName } from '@/utils/StorageCardsUtils';
import { IOrder } from '@/types/OrderTypes';

const GlobalSearch = () => {
	const searchInputRef = useRef<any>(null);
	const [search, setSearch] = useState<string>("");
	const [open, setOpen] = useState<boolean>(false);

	const [searchResults, setSearchResults] = useState<any[] | null>(null);

	const openSearchResults = open && searchResults && searchResults?.length > 0;

	const getSearchResults = () => {
		if (search?.toString()?.length < 2) {
			setSearchResults(null);
			return;
		}
		fetch(`/api/search?searchTerm=${search}`).then(res => res.json()).then((data_res) => {
			setSearchResults(data_res);
		});
	}

	useEffect(() => {
		if (search.toString().length > 2) {
			setOpen(true);
		}

		const timeout = setTimeout(() => {
			getSearchResults();
		}, 500);
		return () => clearTimeout(timeout);
	}, [search]);

	return (
		<>
			<GenericTextField
				ref={searchInputRef}
				placeholder={`Vyhledávání`}
				value={search}
				className={styles.searchInput}
				onChange={(e) => {
					setSearch(e.target.value);
				}}
				onFocus={() => {
					setOpen(true);
				}}
				style={{
					position: `relative`,
					zIndex: 3000
				}}
			/>
			<Popover
				open={openSearchResults || false}
				anchorEl={searchInputRef?.current}
				onClose={() => {
					setOpen(false);
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				marginThreshold={45}
				disablePortal
				disableAutoFocus={true}
				disableEnforceFocus={true}

			>
				<SearchResults
					results={searchResults}
					onClick={() => {
						setOpen(false);
					}}
				/>
			</Popover>
		</>
	)
}

const SearchResults = ({ results, onClick }: {
	results: ISearchCategory[] | null;
	onClick: () => void;
}) => {
	console.log("results", results);
	return (
		<div className={styles.searchResults_holder}>
			{results?.map((category: ISearchCategory) => {
				return (
					<SearchResultsCategory
						key={category.category_name}
						category={category}
						onClick={onClick}
					/>
				)
			})}
		</div>
	)
}

const SearchResultsCategory = ({ category, onClick }: {
	category: ISearchCategory
	onClick: () => void;
}) => {

	const CATEGORY_NAMES = {
		ADDRESS_BOOK: "addressBook",
		STORAGE_CARDS: "storageCards",
		RECEIPTS: "receipts",
		ORDERS: "orders",
		OFFERS: "offers",
	};

	return Array.isArray(category?.category_results) && category?.category_results?.length > 0 ? (
		<div className={styles.category_holder}>
			<p className={styles.category_title}>{category.cateogry_title} ({category?.category_results?.length})</p>
			<div className={styles.category_results_holder}>
				{category?.category_results?.map((item: any) => {
					const categoryName = category?.category_name;
					if (categoryName === CATEGORY_NAMES.ADDRESS_BOOK) {
						return (
							<SearchAddressBookItem
								key={item.id}
								item={item}
								onClick={onClick}
							/>
						)
					} else if (categoryName === CATEGORY_NAMES.STORAGE_CARDS) {
						return (
							<SearchStorageCardItem
								key={item.id}
								item={item}
								onClick={onClick}
							/>
						)
					} else if (categoryName === CATEGORY_NAMES.RECEIPTS) {
						return (
							<SearchReceiptItem
								key={item.id}
								item={item}
								onClick={onClick}
							/>
						)
					} else if (categoryName === CATEGORY_NAMES.ORDERS) {
						return (
							<SearchOrderItem
								key={item.id}
								item={item}
								onClick={onClick}
							/>
						)
					} else if (categoryName === CATEGORY_NAMES.OFFERS) {
						//TODO
						return null;
						return (
							<SearchReceiptItem
								key={item.id}
								item={item}
								onClick={onClick}
							/>
						)
					}
					return null;
				})}
			</div>
		</div>
	) : null;
}

const SearchAddressBookItem = ({ item, onClick }: {
	item: IContact;
	onClick: () => void;
}) => {
	return item?.id ? (
		<SearchCategoryItem
			id={item.id}
			title={getFullName(item)}
			href={`/addressBook/${item.id}/orders`}
			onClick={onClick}
		/>
	) : null;
}

const SearchStorageCardItem = ({ item, onClick }: {
	item: IStorageCard;
	onClick: () => void;
}) => {
	return item?.id ? (
		<SearchCategoryItem
			id={item.id}
			title={getStorageCardName(item) || ""}
			subTitle={undefined}
			rightTitle={`${item?.availability} ks`}
			href={`/warehouse?storageCardId=${item.id}`}
			onClick={onClick}
		/>
	) : null;
}

const SearchReceiptItem = ({ item, onClick }: {
	item: any;
	onClick: () => void;
}) => {
	return item?.id ? (
		<SearchCategoryItem
			id={item.id}
			title={item.delivery_note_number}
			subTitle={getFullName(item)}
			rightTitle={dayjs(item.dateAdded.date).format(DATE_FORMAT)}
			href={`/warehouse/receipts/detail/${item.id}`}
			onClick={onClick}
		/>
	) : null;
}

const SearchOrderItem = ({ item, onClick }: {
	item: IOrder;
	onClick: () => void;
}) => {
	return item?.id ? (
		<SearchCategoryItem
			id={item.id}
			title={item.title}
			subTitle={getFullName(item.customer)}
			rightTitle={item.statusTitle}
			href={`/orders/${item.id}`}
			onClick={onClick}
		/>
	) : null;
}

const SearchCategoryItem = ({ id, title, subTitle, rightTitle, href, onClick }: {
	id: number;
	title: string;
	subTitle?: string;
	rightTitle?: string;
	href: string;
	onClick: () => void;
}) => {
	return (
		<Link
			href={href}
			className={styles.result_item}
			onClick={() => {
				onClick();
			}}
		>
			<p className={styles.item_id}>#{id}</p>
			<span>
				<p className={styles.item_title}>{title}</p>
				<p className={styles.item_subTitle}>{subTitle}</p>
			</span>
			<p className={styles.item_rightTitle}>{rightTitle}</p>
		</Link>
	)
}

export default GlobalSearch;