import { useContext, createContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { makeSwrRequest } from './SwrProvider';
import { useSWRConfig } from 'swr';

const AuthContext = createContext({});

export const AuthProvider = ({ children }: { children: any }) => {

	const router = useRouter();

	const logout = () => {
		fetch("/api/auth/logout", {
			method: "POST"
		}).then(res => res.json()).then(() => {
			router.push({
				pathname: "/"
			});
			location.reload();
			return;
		});
		return;
	}

	const { data: apiStatus } = makeSwrRequest("/api/auth/status", "POST");

	const checkIfLoggedIn = () => {
		return apiStatus?.status || false;
	}

	const [userId, setUserId] = useState<string | null>(null);
	const { data: userData, error: userError, mutate: refreshUserData, isValidating: userIsReloading } = makeSwrRequest(userId ? `/api/users/${userId}` : null);

	useEffect(() => {
		getUserId();
	}, [router.asPath]);

	//console.log("userData", userData);

	const getUserId = () => {
		fetch(`/api/auth/userId`, {
			method: 'POST'
		}).then(res => res.json()).then(({ userId }) => {
			if (userId === null && checkIfLoggedIn() === true) {
				getUserId();
			} else {
				setUserId(userId);
			}
		});
	}

	useEffect(() => {
		getUserId();
		refreshUserData();
	}, [userId, checkIfLoggedIn()]);

	return (
		<AuthContext.Provider value={{
			logout,
			checkIfLoggedIn,
			userData,
			userId: parseInt(userId || ""),
			userIsLoading: (!userData?.id) && userIsReloading
		}}>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuth = (): any => useContext(AuthContext);