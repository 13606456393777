import { CircularProgress, Stack } from "@mui/material"
import { height } from "@mui/system"

const GenericProgress = ({ show, type, size, title }: {
	show: boolean;
	type?: 'circular',
	size?: 'small' | 'normal' | undefined;
	title?: string;
}) => {

	return (
		<>
			{show === true ?
				<Stack direction={`row`} spacing={2} alignItems={`center`} justifyContent={`center`}>
					{!type || type === 'circular' ?
						<div style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							transformOrigin: '50% 50%',
							transform: `scale(${size === 'small' ? `0.6` : `1.0`})`
						}}>
							<CircularProgress color={`primary`} />
						</div>
						: null}
					{title ?
						<span>{title}</span>
						: null}
				</Stack>
				: null}
		</>
	)
}

export default GenericProgress;