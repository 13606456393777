export const mainMenu = [
	{
		page_name: "addressBook",
		icon_name: "PermContactCalendarIcon",
		title: "Adresář",
		top_menu: [
			{
				path: "addressBook",
				page_name: "",
				icon_name: "ListAltIcon",
				title: "Seznam",
				production: true,
			},
			{
				path: "archive",
				page_name: "archive",
				icon_name: "ArchiveIcon",
				title: "Archiv",
				production: false,
			}
		],
		childs: [],
		production: true,
	},
	{
		page_name: "warehouse",
		icon_name: "WarehouseRoundedIcon",
		title: "Sklad",
		top_menu: [
			{
				path: "warehouse",
				page_name: "",
				icon_name: "ListAltIcon",
				title: "Karty",
				production: true,
			},
			{
				page_name: "receipts",
				icon_name: "SystemUpdateAltRoundedIcon",
				title: "Příjemky",
				production: true,
			},
			{
				path: "warehouse",
				page_name: "recieved_creditNotes",
				icon_name: "NoteRoundedIcon",
				title: "Přijaté dobropisy",
				production: false,
			},
			{
				path: "warehouse",
				page_name: "issued_orders",
				icon_name: "AssignmentTurnedInRoundedIcon",
				title: "Vystavené objednávky",
				production: false,
			},
			{
				path: "warehouse",
				page_name: "stockTaking",
				icon_name: "DifferenceRoundedIcon",
				title: "Inventura",
				production: false,
			}
		],
		childs: {
			detail: {
				top_menu: [
					{
						path: "warehouse/detail/[card_id]",
						page_name: "transfers",
						icon_name: "MoveUpIcon",
						title: "Skladové pohyby",
						production: true,
					}
				]
			}
		},
		production: true,
	},
	{
		page_name: "orders",
		icon_name: "FilterNoneRoundedIcon",
		title: "Zakázky",
		top_menu: [
			{
				path: "",
				page_name: "",
				icon_name: "ListAltIcon",
				title: "Seznam",
				production: true,
			},
			{
				path: "",
				page_name: "archive",
				icon_name: "ArchiveIcon",
				title: "Archiv",
				production: true,
			}
		],
		childs: [],
		production: true,
	},
	{
		page_name: "batch",
		icon_name: "AssignmentTurnedInRoundedIcon",
		title: "Výrobní dávky",
		top_menu: [
			{
				path: "",
				page_name: "",
				icon_name: "ListAltIcon",
				title: "Seznam",
				production: true,
			},
			{
				path: "",
				page_name: "archive",
				icon_name: "ArchiveIcon",
				title: "Archiv",
				production: false,
			}
		],
		childs: [],
		production: true,
	},
	{
		page_name: "production",
		icon_name: "HandymanIcon",
		title: "Výroba",
		top_menu: [
			{
				path: "",
				page_name: "",
				icon_name: "ListAltIcon",
				title: "Seznam",
				production: true,
			},
			{
				path: "",
				page_name: "archive",
				icon_name: "ArchiveIcon",
				title: "Archiv",
				production: false,
			}
		],
		childs: [],
		production: true,
	},
	{
		page_name: "management_reports",
		icon_name: "QueryStatsIcon",
		title: "Manažerské sestavy",
		top_menu: [],
		childs: [],
		production: false,
	},
	{
		page_name: "system",
		icon_name: "SettingsRoundedIcon",
		title: "Systém",
		top_menu: [],
		childs: [
			{
				page_name: "settings",
				icon_name: "AppSettingsAltRoundedIcon",
				title: "Nastavení",
				production: true,
			},
			{
				page_name: "warehouse_duplication",
				icon_name: "CopyAllRoundedIcon",
				title: "Duplicity skladu",
				production: true,
			},
			{
				page_name: "departments",
				icon_name: "EngineeringRoundedIcon",
				title: "Pracoviště",
				production: true,
			},
			{
				page_name: "users",
				icon_name: "ManageAccountsRoundedIcon",
				title: "Uživatelé",
				production: true,
			}
		],
		production: false,
	}
];

export const topMenu = [
	{
		path: "addressBook",
		page_name: "list",
		icon_name: "ListAltIcon",
		title: "Seznam"
	},
	{
		path: "addressBook",
		page_name: "archive",
		icon_name: "ArchiveIcon",
		title: "Archiv"
	},
	{
		path: "orders",
		page_name: "",
		icon_name: "ListAltIcon",
		title: "Seznam"
	},
	{
		path: "orders",
		page_name: "archive",
		icon_name: "ArchiveIcon",
		title: "Archiv"
	},
	{
		path: "production_batch",
		page_name: "list",
		icon_name: "ListAltIcon",
		title: "Seznam"
	},
	{
		path: "production_batch",
		page_name: "archive",
		icon_name: "ArchiveIcon",
		title: "Archiv"
	},
	{
		path: "warehouse",
		page_name: "",
		icon_name: "ListAltIcon",
		title: "Karty"
	},
	{
		page_name: "receipts",
		icon_name: "SystemUpdateAltRoundedIcon",
		title: "Příjemky"
	},
	{
		path: "warehouse",
		page_name: "recieved_creditNotes",
		icon_name: "NoteRoundedIcon",
		title: "Přijaté dobropisy"
	},
	{
		path: "warehouse",
		page_name: "issued_orders",
		icon_name: "AssignmentTurnedInRoundedIcon",
		title: "Vystavené objednávky"
	},
	{
		path: "warehouse",
		page_name: "stockTaking",
		icon_name: "DifferenceRoundedIcon",
		title: "Inventura"
	},
	{
		path: "warehouse/detail/[card_id]",
		page_name: "transfers",
		icon_name: "MoveUpIcon",
		title: "Skladové pohyby"
	}
];