import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import HomeIcon from '@mui/icons-material/Home';

import styles from '@/styles/Breadcrumb.module.scss'

export interface IBreadCrumbRoute {
	title: string;
	href: string;
}

interface IBreadcrumb {
	breadCrumbRoute: IBreadCrumbRoute[];
}

const Breadcrumb = ({ breadCrumbRoute }: IBreadcrumb) => {
	return (
		<div className={styles.breadcrumbs}>
			<Breadcrumbs aria-label="breadcrumb" separator={`›`}>
				<Link underline="hover" color="inherit" href="/" className={styles.home_icon_holder}>
					<HomeIcon className={styles.home_icon} />
				</Link>
				{breadCrumbRoute.map((item: IBreadCrumbRoute, index: number) => {
					if (index < breadCrumbRoute.length - 1) {
						return (
							<Link
								underline="hover"
								color="inherit"
								href={item.href}
								key={index}
							>
								{item.title}
							</Link>
						)
					} else {
						return (
							<Typography color="text.primary" key={index}>{item.title}</Typography>
						)
					}
				})}
			</Breadcrumbs>
		</div>
	)
}

export default Breadcrumb;