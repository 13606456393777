import Head from 'next/head';
import NextNProgress from "nextjs-progressbar";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import type { AppProps } from 'next/app'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import '@/styles/globals.scss'
import { useRouter } from 'next/router';
import { AuthProvider } from '@/utils/AuthProvider';
import { MessagesProvider } from '@/utils/MessagesProvider';
import { LoadingProvider } from '@/utils/LoadingProvider';
import Layout from '@/components/Layout';

function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter();
	const theme = createTheme({
		palette: {
			primary: {
				main: 'rgba(52, 73, 94, 1.0)',
			}
		},
	});

	const isLoginPage = router.asPath.includes("/login");

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<ThemeProvider theme={theme}>
				<MessagesProvider>
					<LoadingProvider>
						<AuthProvider>
							<NextNProgress />
							<Head>
								<meta name="apple-mobile-web-app-capable" content="yes" />
							</Head>
							{isLoginPage ?
								<Component {...pageProps} />
								:
								<Layout>
									<Component {...pageProps} />
								</Layout>
							}
						</AuthProvider>
					</LoadingProvider>
				</MessagesProvider>
			</ThemeProvider>
		</LocalizationProvider>
	)
}

export default MyApp