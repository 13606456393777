import { InputAdornment, TextField } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";

import styles from '@/styles/Generic/GenericTextField.module.scss'
import { addZeroToBeginningDecimal, replaceCommaWithDot, replaceLeadingZeros } from "@/utils/Numbers";

interface IGenericTextField {
	size?: 'small' | 'medium' | 'normal';
	thin?: boolean;
	label?: string;
	value: string | number | undefined;
	placeholder?: string;
	type?: "text" | "number";
	maskType?: "decimals"[];
	decimalsLimit?: number;
	defaultValue?: string;
	onChange?: (e: any) => void;
	onClick?: (e: any) => void;
	onEnterPress?: (e: any) => void;
	onFocus?: (e: any) => void;
	startAdornment?: any;
	endAdornment?: any;
	disabled?: boolean;
	fullWidth?: boolean;
	style?: any;
	multiline?: boolean;
	className?: string;
	password?: boolean;
	onKeyPress?: (e: any) => void;
	alignRight?: boolean;
	autoFocus?: boolean;
}

const GenericTextField = forwardRef<any, IGenericTextField>(({ className, size, label, thin, value, placeholder, type, maskType, decimalsLimit, defaultValue, onChange, onClick, onEnterPress, onFocus, startAdornment, endAdornment, disabled, fullWidth, style, multiline, password, onKeyPress, alignRight, autoFocus }, ref) => {
	const formatNumberValue = (value: string) => {
		value = replaceCommaWithDot(value);
		value = replaceLeadingZeros(value);
		value = addZeroToBeginningDecimal(value);
		return value;
	}

	const showLimitedDecimals = type === "number" && maskType?.includes("decimals");

	// const [formatedValue, setFormatedValue] = useState<string>(value);
	// const formatValue = () => {
	// 	if (showLimitedDecimals) {
	// 		const floatValue = parseFloat(value);
	// 		if (isNaN(floatValue)) {
	// 			return "";
	// 		}
	// 		return floatValue.toFixed(decimalsLimit);
	// 	}
	// 	return value;
	// }

	// useEffect(() => {
	// 	if (showLimitedDecimals) {
	// 		setFormatedValue(formatValue());
	// 	}
	// }, [value]);

	//console.log("textFIELDvalue", label, value);

	return (
		<TextField
			inputRef={ref}
			className={`${styles.generic_text_field} ${disabled ? styles.disabled : ``} ${type === 'number' ? styles.alignRight : ``} ${alignRight ? styles.alignRight : ``} ${className}`}
			disabled={Boolean(disabled)}
			fullWidth={fullWidth}
			multiline={multiline}
			type={password ? `password` : ``}
			label={label}
			value={parseFloat(`${value}`) === 0 ? "0" : value || ""}
			placeholder={placeholder}
			size='small'
			variant={disabled || thin ? 'standard' : 'outlined'}
			onChange={(e) => {
				const event = {
					...e,
					target: {
						...e.target,
						value: type === 'number' ? formatNumberValue(e.target.value) : e.target.value
					}
				}
				if (onChange) {
					onChange(event);
				}
			}}
			onClick={onClick}
			onFocus={(e) => {
				if (onFocus) {
					onFocus(e);
				}
				setTimeout(() => {
					e.target.setSelectionRange(0, e.target.value.length);
				}, 100);
			}}
			autoFocus={autoFocus}
			style={{
				width: size == "small" ? "50px" : size == "medium" ? "70px" : "small",
				height: thin ? `25px` : `auto`,
				fontSize: thin ? `0.7rem` : `0.875rem`,
				...style
			}}
			InputProps={{
				startAdornment: startAdornment ?
					<>
						<InputAdornment position="end"><small>{startAdornment}</small></InputAdornment>
					</>
					: null,
				endAdornment: endAdornment ?
					<>
						<InputAdornment position="end">
							<small>
								{endAdornment}
							</small>
						</InputAdornment>
					</>
					: null,
			}}
			InputLabelProps={{ shrink: true }}
			onKeyUp={(e: any) => {
				if (onKeyPress) {
					onKeyPress(e);
				}
				if (e.keyCode === 13) {
					if (!multiline) {
						e.target?.blur();
					}
				}
				if (onEnterPress) {
					if (e.keyCode === 13) {
						onEnterPress(e);
						e.target?.blur();
					}
				}
			}}
		/>
	)
});

GenericTextField.displayName = 'GenericTextField';
export default GenericTextField;