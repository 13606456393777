import { ReactElement } from "react";
import { useAuth } from "./AuthProvider";

type TRole = {
	id: number,
	name: string,
	unlimited: boolean,
	pages: any[],
	sections: any[]
}

export const ROLE_DEFS = {
	ADMIN: 1,
	OPERATOR: 2,
	TECHNOLOGIST: 3,
	SECRETARY: 4,
}

export const useAuthorizator = () => {
	const roles: TRole[] = [
		{
			id: ROLE_DEFS.ADMIN,
			name: 'admin',
			unlimited: true,
			pages: [

			],
			sections: []
		},
		{
			id: ROLE_DEFS.OPERATOR,
			name: 'operator',
			unlimited: false,
			pages: [
				"production"
			],
			sections: []
		},
		{
			id: ROLE_DEFS.TECHNOLOGIST,
			name: 'technologist',
			unlimited: false,
			pages: [
				"addressBook",
				"warehouse",
				"batch",
				"production",
			],
			sections: []
		}
	];

	const checkIfAllowedForRole = (role: number, page: string, section?: string) => {
		const selected_role = roles?.find(item => item.id === role);

		if (selected_role === undefined) {
			return false;
		}
		if (selected_role.unlimited === true) {
			return true;
		} else {
			const sectionsAllowed = section ? selected_role?.sections?.includes(section) : true;
			return selected_role?.pages?.includes(page) && sectionsAllowed;
		}
	}

	return {
		roles,
		checkIfAllowedForRole
	}
}

export const Authorizator = ({ allowOnlyForRoles, page, section, children }: {
	allowOnlyForRoles?: number[];
	page?: string;
	section?: string;
	children: ReactElement;
}) => {
	const { userData } = useAuth();
	const role = userData?.role;
	const { checkIfAllowedForRole } = useAuthorizator();
	const allowed = allowOnlyForRoles && allowOnlyForRoles.includes(role) ? true : page ? checkIfAllowedForRole(role, page, section) : false;
	return allowed ? children : null;
}