import { useState, useEffect, createContext, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import { stripUrlSlashes } from '@/utils/stripSlashesUrl';
import Link from 'next/link';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Breadcrumb, { IBreadCrumbRoute } from '@/components/Breadcrumbs';

import { Chip, ListItemIcon, Menu, MenuItem, useMediaQuery } from '@mui/material';

/* ICONS */
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ArchiveIcon from '@mui/icons-material/Archive';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import FilterNoneRoundedIcon from '@mui/icons-material/FilterNoneRounded';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import SystemUpdateAltRoundedIcon from '@mui/icons-material/SystemUpdateAltRounded';
import NoteRoundedIcon from '@mui/icons-material/NoteRounded';
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AppSettingsAltRoundedIcon from '@mui/icons-material/AppSettingsAltRounded';
import DifferenceRoundedIcon from '@mui/icons-material/DifferenceRounded';
import CopyAllRoundedIcon from '@mui/icons-material/CopyAllRounded';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import HandymanIcon from '@mui/icons-material/Handyman';

//SYSTEM BUTTONS
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import ThirdLevelControls from './controls/ThirdLevelControls';
import { mainMenu, topMenu } from '@/defs/Menu';
import { useAuth } from '@/utils/AuthProvider';
import { useAuthorizator } from '@/utils/Authorizator';
import Image from 'next/image';
import styles from '@/styles/Layout.module.scss'
import GlobalSearch from './Search/Search';
import { Logout, Person } from '@mui/icons-material';
import useBreadcrumbs from '@/utils/useBreadcrumbs';

export const MainContext = createContext<any>({});

const Layout = ({ children }: { children: any; }) => {
    const router = useRouter();
    const currentPages: any[] = router.asPath.split("/").filter(n => n);

    let pages_route: IBreadCrumbRoute[] = [];

    const pagePath = stripUrlSlashes(router.route);
    const topMenuItems = useMemo(() => {
        return mainMenu.find((item: any) => {
            return pagePath.includes(item.page_name);
        })?.top_menu || []
    }, [pagePath]);

    const icons: any = {
        "PermContactCalendarIcon": <PermContactCalendarIcon />,
        "ListAltIcon": <ListAltIcon />,
        "ArchiveIcon": <ArchiveIcon />,
        "AssignmentTurnedInRoundedIcon": <AssignmentTurnedInRoundedIcon />,
        "FilterNoneRoundedIcon": <FilterNoneRoundedIcon />,
        "WarehouseRoundedIcon": <WarehouseRoundedIcon />,
        "SystemUpdateAltRoundedIcon": <SystemUpdateAltRoundedIcon />,
        "NoteRoundedIcon": <NoteRoundedIcon />,
        "AddTaskRoundedIcon": <AddTaskRoundedIcon />,
        "FlagRoundedIcon": <FlagRoundedIcon />,
        "SettingsRoundedIcon": <SettingsRoundedIcon />,
        "AppSettingsAltRoundedIcon": <AppSettingsAltRoundedIcon />,
        "DifferenceRoundedIcon": <DifferenceRoundedIcon />,
        "CopyAllRoundedIcon": <CopyAllRoundedIcon />,
        "EngineeringRoundedIcon": <EngineeringRoundedIcon />,
        "ManageAccountsRoundedIcon": <ManageAccountsRoundedIcon />,
        "MoveUpIcon": <MoveUpIcon />,
        "QueryStatsIcon": <QueryStatsIcon />,
        "HandymanIcon": <HandymanIcon />
    }

    const [thirdLevelContent, setThirdLevelContent] = useState(null);

    const { userData } = useAuth();
    const { checkIfAllowedForRole } = useAuthorizator();

    const isMobile = useMediaQuery('(max-width: 680px)');

    const menuRef = useRef<any>(null);
    const [menuHeight, setMenuHeight] = useState<number>(50);

    useEffect(() => {
        const handleResize = () => {
            setMenuHeight(menuRef?.current?.clientHeight);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const pageName = router.asPath?.split("/").filter(n => n)[0];
        if (pageName !== "/" && !checkIfAllowedForRole(userData?.role, pageName)) {
            // router.push({
            //     pathname: "/"
            // });
            return;
        }
    }, [router]);

    return (
        <>
            <MainContext.Provider value={{
                thirdLevelContent: thirdLevelContent,
                setThirdLevelContent: setThirdLevelContent
            }}>
                <div
                    className={styles.invisible_menu_holder}
                    style={{
                        height: `calc(${menuHeight}px + 10px)`
                    }}
                />
                <div
                    className={styles.menu_holder}
                    ref={menuRef}
                >
                    <div className={`${styles.first_level} ${isMobile ? styles.mobile : ``}`}>
                        <div className={styles.left_side}>
                            <div className={`${styles.logo_holder} ${isMobile ? styles.small : ``}`}>
                                <Link href={{ pathname: "/" }}>
                                    {!isMobile ?
                                        <h1><span className={styles.bold}>TOROTECH</span> ERP</h1>
                                        :
                                        <Image
                                            alt={`logo`}
                                            src={isMobile ? `/toro_logo_icon.png` : `/toro_logo.png`}
                                            fill
                                            style={{
                                                objectFit: 'contain'
                                            }}
                                        />
                                    }
                                </Link>
                            </div>
                            {mainMenu.filter((menu_item: any) => {
                                return checkIfAllowedForRole(
                                    userData?.role,
                                    menu_item.page_name
                                ) && (menu_item.production === true || process.env.NEXT_PUBLIC_IS_PRODUCTION === "0")
                            })?.map((menu_item: any, index: number) => {
                                const isActive = router.asPath.startsWith(`/${menu_item.page_name}`);
                                return (
                                    <div className={styles.menu_item_holder} key={`menuItem${index}`}>
                                        <MakeLink
                                            hasLink={true}
                                            isChild={false}
                                            page_name={menu_item.page_name}
                                        >
                                            <Tooltip title={menu_item.title} arrow>
                                                <IconButton className={`${styles.menu_item} ${isActive ? styles.active : ""}`}>
                                                    {
                                                        menu_item.icon_name ?
                                                            icons[menu_item.icon_name]
                                                            : null
                                                    }
                                                </IconButton>
                                            </Tooltip>
                                        </MakeLink>
                                        {isActive ?
                                            <div className={styles.subMenu_holder}>
                                                {topMenuItems?.filter((topmenu_item: any) => {
                                                    return (topmenu_item.production === true || process.env.NEXT_PUBLIC_IS_PRODUCTION === "0")
                                                })?.map((item: any, topMenu_index: number) => {
                                                    var item_path = item.path + (item.page_name.length > 0 ? "/" + item.page_name : "");
                                                    item_path = stripUrlSlashes(item_path);
                                                    item_path = "/" + item_path;

                                                    return (
                                                        <MakeLink
                                                            hasLink={true}
                                                            isChild={true}
                                                            page_name={item.page_name}
                                                            parent_page_name={menu_item.page_name}
                                                            key={`topMenu${topMenu_index}`}
                                                        >
                                                            <Tooltip title={item.title} arrow>
                                                                <IconButton className={`${styles.menu_item} ${currentPages.includes(item.page_name) || (currentPages?.length === 1 && item.page_name === "") ? styles.active : ""}`}>
                                                                    {
                                                                        item.icon_name ?
                                                                            icons[item.icon_name]
                                                                            : null
                                                                    }
                                                                </IconButton>
                                                            </Tooltip>
                                                        </MakeLink>
                                                    )
                                                })}
                                            </div>
                                            : null
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        <div className={styles.right_side}>
                            <div className={styles.breadcrumbs_holder}>
                                <AppRouteBreadcrumbs />
                            </div>
                            <div className={styles.search_holder}>
                                <GlobalSearch />
                            </div>
                            {/* <div className={styles.breadcrumbs_holder}>
                                <Breadcrumb
                                    breadCrumbRoute={pages_route}
                                />
                            </div> */}
                            {/* <Chip icon={<LanIcon />} className={styles.logout_button} label="Get local IP" onClick={() => {
                            setOpenLocalIP(true);
                        }} /> */}
                            {/* <GetLocalIPCommand open={openLocalIP} setOpen={setOpenLocalIP} /> */}
                            <ProfileMenu />
                        </div>
                    </div>
                </div>

                {thirdLevelContent ?
                    <ThirdLevelControls>
                        {thirdLevelContent}
                    </ThirdLevelControls>
                    : null
                }

                <div
                    className={styles.content_holder}
                >
                    {children}
                </div>
            </MainContext.Provider>
        </>
    );
}

const AppRouteBreadcrumbs = () => {
    const { breadcrumbs } = useBreadcrumbs(mainMenu);

    return (
        <Breadcrumb
            breadCrumbRoute={breadcrumbs?.map((routeItem: any) => {
                return {
                    title: routeItem?.title,
                    href: `/${routeItem?.page_name}`
                }
            })}
        />
    )
}

const ProfileMenu = () => {
    const router = useRouter();
    const { userData } = useAuth();
    const profileMenuRef = useRef<any>(null);
    const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);

    const profileName = userData?.firstName && userData?.lastName ? `${userData?.firstName} ${userData?.lastName}` : userData?.email;

    const logout = () => {
        fetch(`/api/auth/logout`, {
            method: 'POST'
        }).then(res => res.json()).then((data_res) => {
            router.reload();
        });
    }

    return (
        <>
            <Chip
                className={styles.profileAvatar_holder}
                ref={profileMenuRef}
                avatar={<Person />}
                label={profileName}
                onClick={() => {
                    setProfileMenuOpen(true);
                }}
            />
            <Menu
                open={profileMenuOpen}
                onClose={() => {
                    setProfileMenuOpen(false);
                }}
                anchorEl={profileMenuRef?.current}
            >
                <MenuItem
                    onClick={() => {
                        logout();
                    }}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Odhlásit se
                </MenuItem>
            </Menu>
        </>
    )
}

interface IMakeLink {
    hasLink: boolean;
    isChild: boolean | undefined;
    page_name: string;
    parent_page_name?: string;
    children: any;
}

const MakeLink = ({ hasLink, isChild, page_name, parent_page_name, children }: IMakeLink) => {
    return !hasLink ? children : (
        <>
            <Link
                href={{
                    pathname: !isChild ? '/[page_name]/' : '/[parent_page_name]/[page_name]/',
                    query: isChild ? {
                        page_name: page_name,
                        parent_page_name: parent_page_name
                    } : {
                        page_name: page_name
                    }
                }}
                shallow={false}
            >
                {children}
            </Link>
        </>
    );
}

export default Layout;