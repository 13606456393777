import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

const useBreadcrumbs = (menuItems: any[]) => {
	const router = useRouter();
	const initialRoute = router.asPath;
	const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);

	useEffect(() => {
		const findPageByName = (pageName: string, dataArray: any[]) => {
			return dataArray.find(item => item.page_name === pageName);
		};

		const routeSegments = initialRoute.split('/').filter(segment => segment !== '');

		let currentData = menuItems;
		const newBreadcrumbs: any[] = [];

		for (let i = 0; i < routeSegments.length; i++) {
			const segment = routeSegments[i];
			const page = findPageByName(segment, currentData);

			if (page) {
				newBreadcrumbs.push({ page_name: page.page_name, title: page.title });
				currentData = page.top_menu || [];
			} else {
				break;
			}
		}

		setBreadcrumbs(newBreadcrumbs);
	}, [menuItems, initialRoute]);

	return { breadcrumbs };
};

export default useBreadcrumbs;